import { retryablePromise } from "~/helpers/retryablePromise";

export async function parseUserAgent(userAgentString?: string) {
  try {
    const UAParser = (await retryablePromise(() => import("ua-parser-js")))
      .default;
    const parser = new UAParser(userAgentString);
    const parserResult = parser.getResult();
    return parserResult;
  } catch (err) {
    return null;
  }
}

export function checkIsDesktop(parserResult: UAParser.IResult) {
  return !parserResult.device.type;
}

export function checkIsTablet(parserResult: UAParser.IResult) {
  return parserResult.device.type === "tablet";
}

export function isDeviceIOS() {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
}
