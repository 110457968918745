import { defineStore } from "pinia";
import {
  checkIsDesktop,
  parseUserAgent,
  checkIsTablet,
} from "~/helpers/userAgent";

const useClientTypeStore = defineStore("clienType", {
  state: () => {
    return {
      isDesktop: undefined as boolean | undefined,
      isMobile: undefined as boolean | undefined,
      isTablet: undefined as boolean | undefined,
      os: "",
      browserName: "",
    };
  },
  actions: {
    async determineClientType(userAgentString?: string) {
      const parserResult = await parseUserAgent(userAgentString);
      if (!parserResult) {
        return;
      }
      this.os = parserResult.os.name || "";
      this.browserName = parserResult.browser.name || "";
      this.isDesktop = checkIsDesktop(parserResult);
      this.isTablet = checkIsTablet(parserResult);
      this.isMobile = !this.isDesktop;
    },
  },
});

export default useClientTypeStore;
